"use strict";

/**
 * @ngdoc function
 * @name informaApp.service:DrugHideSvc
 * @description
 * # DrugHideSvc
 * Service of the informaApp
 */

angular.module("informaApp")
	.service("DrugHideSvc", ["FilterService", "DiseaseService", "DrugIndicationMapper", function(FilterService, DiseaseService, DrugIndicationMapper) {
		var diseasesData;

		function sortIndications (data, by, id) {
			var indications = [], i;

			switch (by) {
				case 'disease': {
					var subDiseases = getIdsByParentId(data.subDiseases, id);

					indications = data.indications
						.filter(function(x) { return subDiseases.indexOf(x.parentId) > -1; })
						.map(function (x) { return x.id; });

					break;
				}
				case 'subdisease': {
					indications = getIdsByParentId(data.indications, id);
					break;
				}
			}
			return indications;
		}

		function getIdsByParentId(data, id) {
			return data
				.filter(function(x) { return x.parentId === id; })
				.map(function (x) { return x.id; });
		}

		function filterHiddenDrugs (filter, params, items, hidden, field) {
			if(items !== 'all') { params[field] = Array.isArray(items) ? items : [items] }

			params.hiddendrugs = hidden;

			return FilterService[filter](params)
				.then(function(res) { return DrugIndicationMapper.mapArray(res.data.data)[0] });
		}

		return {
			updateDrugs: function (itemId, hiddenDrugs) {
				var current = _.merge({}, FilterService.getCurrentFilter()),
					field = current.field,
					by = current.filter.match(/By.+$/i)[0]
						.substring(2)
						.toLowerCase();

				if(by === 'disease' || by === 'subdisease') {

					if(!diseasesData) {
						return DiseaseService.getDiseases()
							.then(function(res) {
								diseasesData = res;

								return filterHiddenDrugs(current.filter, current.params, sortIndications(diseasesData, by, itemId), hiddenDrugs, field);
							});
					} else {
						return filterHiddenDrugs(current.filter, current.params, sortIndications(diseasesData, by, itemId), hiddenDrugs, field);
					}

				} else {
					return filterHiddenDrugs(current.filter, current.params, itemId, hiddenDrugs, field);
				}
			}
		}
	}]);
